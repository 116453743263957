import "bootstrap/dist/css/bootstrap.min.css"
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import useToken from './functions/useToken';
import LogIn from "./screens/LogIn";
import NavigationBar from "./screens/NavigationBar";
import Home from "./screens/Home";
import RegisterUser from "./screens/RegisterUser";
import Video from "./screens/Video";
import Projects from "./screens/Projects";
import Catalogs from "./screens/Catalogs";
import Scenarios from "./screens/Scenarios";
import Vehicles from "./screens/EgoVehicles";
import Alerts from "./screens/Alerts";


function App() {

  const {token, setToken} = useToken();

  if(!token || token == null) {
    return <LogIn setToken={setToken} />
  }


  return (
    <BrowserRouter>
      <div style={{'position': 'relative', 'display': 'flex', 'flexDirection': 'column'}}>
        <NavigationBar setToken={setToken}/>
        <div className="content">
          <Routes>
            <Route exact path="/" element={<Home setToken={setToken} />}></Route>
            <Route exact path="/video" element={<Video setToken={setToken} />}></Route>
            <Route exact path="/register" element={<RegisterUser setToken={setToken}/>}></Route>     
            <Route exact path="/catalogs" element={<Catalogs setToken={setToken}/>}></Route>     
            <Route exact path="/projects" element={<Projects setToken={setToken}/>}></Route>     
            <Route exact path="/scenarios" element={<Scenarios setToken={setToken}/>}></Route>
            <Route exact path="/ego" element={<Vehicles setToken={setToken}/>}></Route>
            <Route exact path="/alerts" element={<Alerts setToken={setToken}/>}></Route>
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
