export default function dateFormatter(dateString) {
    const dateLast = new Date(dateString);

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const monthName = months[dateLast.getMonth()];
    const year = dateLast.getFullYear();
    const dayNum = dateLast.getDate();
    const hours = (0 + dateLast.getHours().toString()).slice(-2);
    const minutes = (0 + dateLast.getMinutes().toString()).slice(-2);
    const seconds = (0 + dateLast.getSeconds().toString()).slice(-2);

    const formattedDate = `${dayNum} ${monthName} ${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    
    var toReturn = formattedDate + ' at ' + formattedTime;
    return toReturn;
}