import React, { useEffect, useState } from 'react';
import api_request from '../functions/api_request';
import { Spinner, Toast, ToastContainer, Button, Modal, InputGroup, Form } from 'react-bootstrap';
import { MaterialReactTable } from 'material-react-table';
import { Box } from '@mui/material';

import vars from "../vars.json";


function Scenarios(props){

    const [alertsConfig, setAlertsConfig] = useState({
        show: false,
        variant: "success",
        message: "Testing alert ..."
    });
    function closeAlert(){
        setAlertsConfig({...alertsConfig, show: false});
    }
    const [dataLoading, setDataLoading] = useState(true);
    const [scenarios, setScenarios] = useState([]);
    const [newScenarioModal, setNewScenarioModal] = useState(false);
    const handleShowNewScenario = () => setNewScenarioModal(true);
    const handleCloseNewScenario = () => setNewScenarioModal(false);
    const [newScenario, setNewScenario] = useState({});

    async function getScenarios(){
        setDataLoading(true);
        var dataReceived = await api_request("/scenarios", 'GET', localStorage.getItem('token'), null);
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            setScenarios(dataReceived.message);
        }
        setDataLoading(false);
    }

    async function registerNewScenario(){
        var dataReceived = await api_request("/scenario/new", 'POST', localStorage.getItem('token'), JSON.stringify(newScenario));
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            if (dataReceived.error){
                setAlertsConfig({
                    show: true,
                    variant: "danger",
                    message:"Error registering scenario: " + dataReceived.message
                });
            } else {
                setAlertsConfig({
                    show: true,
                    variant: "success",
                    message:"Scenario registered correctly!"
                });
            }
            getScenarios();
            setNewScenario({});
            handleCloseNewScenario();
        }
    }

    const scenariosColumns = [
        {
            accessorKey: 'name',
            header: 'Name',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },
        {
            accessorKey: 'type',
            header: 'Scenario Type',
            filterVariant: 'multi-select',
            filterSelectOptions: vars.scenario.type,
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },
        {
            accessorKey: 'location.lat',
            header: 'Latitude',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },
        {
            accessorKey: 'location.lon',
            header: 'Longitude',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        }
    ]

    // eslint-disable-next-line 
    useEffect(() => {async function fethScenarios(){await getScenarios()} fethScenarios();}, []);

    return(
        <div stle={{padding: '1em'}}>
            <div className='div-for-table'>
                <div style={{padding: '3em'}}>
                    <h3>Scenarios</h3>
                    <p>Manage the available recording scenarios</p>
                    {
                        dataLoading ? <div style={{display: 'flex', justifyContent: 'center', margin: '17vh'}}>
                            <Spinner animation='grow' style={{ color: '#9B820C'}}/>
                        </div> : <div style={{margin: '3em'}}>
                            <MaterialReactTable 
                                columns={scenariosColumns} 
                                data={scenarios} 
                                state={{density: 'comfortable'}} 
                                initialState={{pagination: {pageSize: 10, pageIndex: 0}, showColumnFilters: true}} 
                                enableDensityToggle={false} 
                                muiTableBodyCellProps={{sx: {wordBreak: 'break-word', whiteSpace: 'normal'}}} 
                                muiTableProps={{sx: {tableLayout: 'fixed'}}} 
                                defaultColumn={{minSize: 10}} 
                                renderTopToolbarCustomActions={({table}) => (
                                    <Box sx={{ display: 'flex', gap: '1rem', p: '4px'}}>
                                        <Button 
                                            color="secondary" 
                                            style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} 
                                            onClick={handleShowNewScenario}>
                                                New Scenario
                                        </Button>
                                    </Box>
                                )}
                                />
                        </div>
                    }
                </div>
            </div>
            <Modal show={newScenarioModal} onHide={handleCloseNewScenario}>
                <Modal.Header closeButton>
                <Modal.Title>Register new Scenario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text>Name</InputGroup.Text>
                            <Form.Control 
                                placeholder='Example: Parking 1 FICOSA'
                                aria-describedby='basic-addon1'
                                onChange={(event) => {
                                    setNewScenario({...newScenario, name: event.target.value});
                                }}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text>Latitude</InputGroup.Text>
                            <Form.Control 
                                placeholder='Example: 41.2231'
                                aria-describedby='basic-addon1'
                                onChange={(event) => {
                                    setNewScenario({...newScenario, location: {...newScenario.location, lat: event.target.value}});
                                }}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text>Longitude</InputGroup.Text>
                            <Form.Control 
                                placeholder='Example: 2.123411'
                                aria-describedby='basic-addon1'
                                onChange={(event) => {
                                    setNewScenario({...newScenario, location: {...newScenario.location, lon: event.target.value}});
                                }}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                                <InputGroup.Text>Scneario Type</InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(event) => setNewScenario({...newScenario, type: event.target.value})}>
                                    <option>Select one ...</option>
                                    {
                                        vars.scenario.type ? vars.scenario.type.map((type) => <option key={type} value={type}>{type}</option>) : <option></option>
                                    }
                                </Form.Select>
                        </InputGroup>
                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseNewScenario}>
                    Close
                </Button>
                <Button variant="primary" onClick={registerNewScenario} style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} >
                    Create Scenario
                </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                className="p-3"
                position="top-end"
                style={{ zIndex: 2 }}
                >
                <Toast onClose={closeAlert} show={alertsConfig.show} bg={alertsConfig.variant}>
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">RESIM 3.0</strong>
                    </Toast.Header>
                    <Toast.Body style={{'color': 'white'}}>{alertsConfig.message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}

export default Scenarios;