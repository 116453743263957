import React, { useEffect, useState } from 'react';
import api_request from '../functions/api_request';
import { Spinner, Toast, ToastContainer, Button, Modal, InputGroup, Form } from 'react-bootstrap';
import { MaterialReactTable } from 'material-react-table';
import { Box } from '@mui/material';

import vars from "../vars.json";


function Vehicles(props){

    const [alertsConfig, setAlertsConfig] = useState({
        show: false,
        variant: "success",
        message: "Testing alert ..."
    });

    function closeAlert(){
        setAlertsConfig({...alertsConfig, show: false});
    }
    const [dataLoading, setDataLoading] = useState(true);
    const [vehicles, setVehicles] = useState([]);
    const [newVehicleModal, setNewVehicleModal] = useState(false);
    const handleShowNewVehicle = () => setNewVehicleModal(true);
    const handleCloseNewVehicle = () => setNewVehicleModal(false);
    const [newVehicle, setNewVehicle] = useState({});

    async function getVehicles(){
        setDataLoading(true);
        var dataReceived = await api_request("/vehicles", 'GET', localStorage.getItem('token'), null);
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            setVehicles(dataReceived.message);
        }
        setDataLoading(false);
    }

    async function registerNewVehicle(){
        var dataReceived = await api_request("/vehicle/new", 'POST', localStorage.getItem('token'), JSON.stringify(newVehicle));
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            if (dataReceived.error){
                setAlertsConfig({
                    show: true,
                    variant: "danger",
                    message:"Error registering vehicle: " + dataReceived.message
                });
            } else {
                setAlertsConfig({
                    show: true,
                    variant: "success",
                    message:"Vehicle registered correctly!"
                });
            }
            getVehicles();
            setNewVehicle({});
            handleCloseNewVehicle();
        }
    }

    const vehiclesColumns = [
        {
            accessorKey: 'plate',
            header: 'Plate',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },
        {
            accessorKey: 'brand',
            header: 'Brand',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },
        {
            accessorKey: 'model',
            header: 'Model',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },
        {
            accessorKey: 'type',
            header: 'Vehicle Type',
            filterVariant: 'multi-select',
            filterSelectOptions: vars.vehicle.type,
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        }
    ]

    // eslint-disable-next-line 
    useEffect(() => {async function fethVehicles(){await getVehicles()} fethVehicles();}, []);


    return(
        <div stle={{padding: '1em'}}>
            <div className='div-for-table'>
                <div style={{padding: '3em'}}>
                    <h3>EGO Vehicles</h3>
                    <p>Manage the available recording vehicles (EGO)</p>
                    {
                        dataLoading ? <div style={{display: 'flex', justifyContent: 'center', margin: '17vh'}}>
                            <Spinner animation='grow' style={{ color: '#9B820C'}}/>
                        </div> : <div style={{margin: '3em'}}>
                            <MaterialReactTable 
                                columns={vehiclesColumns} 
                                data={vehicles} 
                                state={{density: 'comfortable'}} 
                                initialState={{pagination: {pageSize: 10, pageIndex: 0}, showColumnFilters: true}} 
                                enableDensityToggle={false} 
                                muiTableBodyCellProps={{sx: {wordBreak: 'break-word', whiteSpace: 'normal'}}} 
                                muiTableProps={{sx: {tableLayout: 'fixed'}}} 
                                defaultColumn={{minSize: 10}} 
                                renderTopToolbarCustomActions={({table}) => (
                                    <Box sx={{ display: 'flex', gap: '1rem', p: '4px'}}>
                                        <Button 
                                            color="secondary" 
                                            style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} 
                                            onClick={handleShowNewVehicle}>
                                                New Vehicle
                                        </Button>
                                    </Box>
                                )}
                                />
                        </div>
                    }
                </div>
            </div>
            <Modal show={newVehicleModal} onHide={handleCloseNewVehicle}>
                <Modal.Header closeButton>
                <Modal.Title>Register new EGO vehicle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text>Plate</InputGroup.Text>
                            <Form.Control 
                                placeholder='Example: 7799LVM'
                                aria-describedby='basic-addon1'
                                onChange={(event) => {
                                    setNewVehicle({...newVehicle, plate: event.target.value});
                                }}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text>Brand</InputGroup.Text>
                            <Form.Control 
                                placeholder='Example: CUPRA'
                                aria-describedby='basic-addon1'
                                onChange={(event) => {
                                    setNewVehicle({...newVehicle, brand: event.target.value});
                                }}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text>Model</InputGroup.Text>
                            <Form.Control 
                                placeholder='Example: FORMENTOR'
                                aria-describedby='basic-addon1'
                                onChange={(event) => {
                                    setNewVehicle({...newVehicle, model: event.target.value});
                                }}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                                <InputGroup.Text>Project Type</InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(event) => setNewVehicle({...newVehicle, type: event.target.value})}>
                                    <option>Select one ...</option>
                                    {
                                        vars.vehicle.type ? vars.vehicle.type.map((type) => <option key={type} value={type}>{type}</option>) : <option></option>
                                    }
                                </Form.Select>
                        </InputGroup>
                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseNewVehicle}>
                    Close
                </Button>
                <Button variant="primary" onClick={registerNewVehicle} style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} >
                    Create vehicle
                </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                className="p-3"
                position="top-end"
                style={{ zIndex: 2 }}
                >
                <Toast onClose={closeAlert} show={alertsConfig.show} bg={alertsConfig.variant}>
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">RESIM 3.0</strong>
                    </Toast.Header>
                    <Toast.Body style={{'color': 'white'}}>{alertsConfig.message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}

export default Vehicles;