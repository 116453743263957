const config = require("../config.json");

async function api_request(url, method, token, body){
    var complete_url = config['url'] + url;
    let options = {
        method: method,
        mode: 'cors',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Access-Control-Allow-Origin': '*',
            'x-access-token': token
        }
    }

    let response = await fetch(complete_url, options).catch(err => {
        alert("API ERROR: " + err);
        localStorage.removeItem('token');
        return;
    });

    if (response.status !== 200){
        if (response.status === 401){
            //alert("Unauthorized!")
            localStorage.removeItem('token');
            return false;
        }
        console.log("API ERROR: Can't get API information")
    }

    var dataReceived;
    if(response.headers.get('content-type') === "application/json"){
        dataReceived = await response.json();
    }
   
    
    return dataReceived;
}

export default api_request;