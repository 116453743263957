import React, { useEffect, useState } from 'react';
import api_request from '../functions/api_request';
import { ProgressBar, Button, Modal, Form, InputGroup, Toast, ToastContainer, ListGroup, Badge } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

const vars = require("../vars.json");


function Projects(props) {
    const navigate = useNavigate();
    const [alertsConfig, setAlertsConfig] = useState({
        show: false,
        variant: "success",
        message: "Testing alert ..."
    });
    const [projectList, setProjectList] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});

    // Edit project
    const [projectUpdate, setProjectUpdate] = useState({});
    const [showEditProjectModal, setShowEditProjectModal] = useState(false);
    const handleShowEditProject = async () => {
        await setProjectUpdate({...projectUpdate, code: selectedProject.code});
        setShowEditProjectModal(true);
    }
    const handleCloseEditProject = () => setShowEditProjectModal(false);

    async function updateProject(){
        var dataReceived = await api_request("/project", 'POST', localStorage.getItem('token'), JSON.stringify(projectUpdate));
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            if (dataReceived.error){
                setAlertsConfig({
                    show: true,
                    variant: "danger",
                    message:"Error updating project: " + dataReceived.message
                });
            } else {
                setAlertsConfig({
                    show: true,
                    variant: "success",
                    message:"Project updated correctly!"
                });
            }
            await getProjectInfo();
            setProjectUpdate({});
            handleCloseEditProject();
        }
    }

    // Add catalog to project
    const [showAddCatModal, setShowAddCatModal] = useState(false);
    const [catalogs, setCatalogs] = useState([]);
    const handleShowAddCat = async () => {
        await getAllCatalogs()
        setShowAddCatModal(true)
    }
    const handleCloseAddCat = () => setShowAddCatModal(false);

    async function getAllCatalogs(){
        var dataReceived = await api_request("/catalogs", 'GET', localStorage.getItem('token'), null);
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            let allCatalogs = dataReceived.message;

            for (let i = 0; i < allCatalogs.length; i++){
                allCatalogs[i].checked = false;
                for (let j = 0; j < selectedProject.catalogs.length; j++){
                    console.log(selectedProject.catalogs[j]._id + " vs " + allCatalogs[i]._id)
                    if (selectedProject.catalogs[j]._id === allCatalogs[i]._id){
                        allCatalogs[i].checked = true;
                        console.log("TRUE")
                    }
                }                    
            }
            setCatalogs(allCatalogs);
        }
    }

    async function updateCatalogCheck(id, value){
        let newCat = catalogs;
        for (let i = 0; i < newCat.length; i++){
            if (newCat[i]._id === id){
                newCat[i].checked = value
            }
        }
        await setCatalogs(newCat);
    }

    async function updateCatalogs(){
        var newCatalogsList = [];
        var projectCopy = projectUpdate;
        projectCopy.code = selectedProject.code;
        for (let i = 0; i < catalogs.length; i++) {
            if (catalogs[i].checked === true){
                newCatalogsList.push(catalogs[i]._id);
            }
        }
        projectCopy.catalogs = newCatalogsList;
        await setProjectUpdate(projectCopy);
        await updateProject();
        handleCloseAddCat();
    }

    // New project
    const project_types = vars.project.type;
    const [newProject, setNewProject] = useState({
        code: "",
        type: "",
        name: "",
        description: ""
    });
    const [showNewProjectModal, setShowNewProjectModal] = useState(false);
    const handleShowNewProject = () => setShowNewProjectModal(true);
    const handleCloseNewProject = () => setShowNewProjectModal(false);
    
    async function registerNewProject(){
        var dataReceived = await api_request("/project", 'POST', localStorage.getItem('token'), JSON.stringify(newProject));
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            if (dataReceived.error){
                setAlertsConfig({
                    show: true,
                    variant: "danger",
                    message:"Error registering project: " + dataReceived.message
                });
            } else {
                setAlertsConfig({
                    show: true,
                    variant: "success",
                    message:"Project registered correctly!"
                });
            }
            getProjects();
            setNewProject({});
            handleCloseNewProject();
        }
    }
    
    function closeAlert(){
        setAlertsConfig({...alertsConfig, show: false});
    }

    async function getProjects(){
        var dataReceived = await api_request("/projects", 'GET', localStorage.getItem('token'), null);
        if (dataReceived === false){
            props.setToken(null);
        } else {
            let newDataProjects = dataReceived.message;
            await setSelectedProject(newDataProjects[0]);
            setProjectList(newDataProjects);
        }
    }

    async function getProjectInfo(){
        var dataReceived = await api_request("/project/" + localStorage.getItem('projectSelected'), 'GET', localStorage.getItem('token'), null);
        if (dataReceived === false){
            props.setToken(null);
        } else {
            let newSelProject = dataReceived.message;
            await setSelectedProject(newSelProject);
        }
    }

    // eslint-disable-next-line 
    useEffect(() => {async function fethProjects(){await getProjects()} fethProjects();}, []);

    return(
        <div stle={{padding: '6em', paddingTop: '3em'}}>
           <div className='left-column'>
                <div className='title-left'>
                    <h5>PROJECTS</h5>
                </div>
                <hr style={{margin: '.5em', marginTop: 0, color: '#9B820C', opacity: '1'}}></hr>
                <div style ={{margin: '.5em', overflow: 'auto', height: '60vh'}}>
                    <ListGroup as="ol" variant='flush'>
                        {
                            projectList.length !== 0 ? projectList.map((project) => (
                                <ListGroup.Item
                                    as="li"
                                    className='d-flex justify-content-between align-items-start'
                                    action
                                    key={project.code}
                                    onClick={() => {
                                        localStorage.setItem('projectSelected', project.code);
                                        getProjectInfo();
                                    }}
                                >
                                    <div className='ms-2 me-auto'>
                                        <div className='fw-bold'>{project.code}</div>
                                        {project.name}
                                    </div>
                                    {project.type}
                                </ListGroup.Item>
                            )) : 
                            
                            <ListGroup.Item
                                as='li'
                                className='d-flex align-items-center justify-content-center'
                            ><div>
                                Projects not found :(
                            </div>

                            </ListGroup.Item>
                        }
                    </ListGroup>
                </div>
                <div style={{margin: '1.5em', display: 'flex', justifyContent: 'center'}}><Button style={{ backgroundColor: '#9B820C', borderColor: '#9B820C'}} onClick={handleShowNewProject}>NEW PROJECT</Button></div>
            </div>
            <div className='right-panel'>
                <h2>{selectedProject?.code ? selectedProject?.code : ""}</h2>
                
                <div className='data-div'>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <p><b>Project Name   </b>{selectedProject?.name}</p>
                            <p><b>Project Code   </b>{selectedProject?.code}</p>
                        </div>
                        <div>
                            <p><b>Project Description   </b>{selectedProject?.description}</p>
                            <p><b>Project Type   </b>{selectedProject?.type}</p>
                        </div>
                        <div>
                            <Button onClick={handleShowEditProject} style={{padding: 0, backgroundColor: '#9B820C', borderColor: '#9B820C'}}><EditIcon></EditIcon></Button>
                        </div>
                    </div>
                </div>

                <Modal show={showEditProjectModal} onHide={handleCloseEditProject}>
                    <Modal.Header closeButton>
                    <Modal.Title>Update project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <InputGroup className='mb-3'>
                                <InputGroup.Text>Project Code</InputGroup.Text>
                                <Form.Control 
                                    disabled
                                    placeholder={selectedProject?.code}
                                    defaultValue={selectedProject?.code}
                                    aria-describedby='basic-addon1'
                                />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <InputGroup.Text>Project Name</InputGroup.Text>
                                <Form.Control 
                                    placeholder={selectedProject?.name}
                                    defaultValue={selectedProject?.name}
                                    aria-describedby='basic-addon1'
                                    onChange={(event) => {
                                        setProjectUpdate({...projectUpdate, name: event.target.value});
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                    <InputGroup.Text>Project Type</InputGroup.Text>
                                    <Form.Select aria-label="Default select example" defaultValue={selectedProject?.type} onChange={(event) => setProjectUpdate({...projectUpdate, type: event.target.value})}>
                                        <option>Select one ...</option>
                                        {
                                            project_types ? project_types.map((type) => <option key={type} value={type}>{type}</option>) : <option></option>
                                        }
                                    </Form.Select>
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <InputGroup.Text>Description</InputGroup.Text>
                                <Form.Control 
                                    placeholder={selectedProject?.description}
                                    defaultValue={selectedProject?.description}
                                    aria-describedby='basic-addon1'
                                    onChange={(event) => {
                                        setProjectUpdate({...projectUpdate, description: event.target.value});
                                    }}
                                />
                            </InputGroup>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditProject}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={updateProject} style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} >
                        Update project
                    </Button>
                    </Modal.Footer>
                </Modal>

                <div style={{display: 'flex', marginBottom: '-10px'}}>
                    <h5>Catalogs</h5>
                    <Button onClick={handleShowAddCat} style={{padding: 0, marginLeft: '20px', backgroundColor: '#9B820C', borderColor: '#9B820C'}}><AddIcon></AddIcon></Button>
                </div>

                <Modal show={showAddCatModal} onHide={handleCloseAddCat}>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Catalogs</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <ListGroup as="ol" numbered>
                        {
                            (catalogs.length !== 0 ? catalogs.map((catalog) => <ListGroup.Item as="li"
                            className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{catalog.name}</div>
                                    {catalog.description}
                                </div>
                                <div className="ms-2 me-auto">
                                    <Badge bg="primary" pill>{catalog.currentCounter + "/" + catalog.goal}</Badge>
                                    <Form.Check defaultChecked={catalog.checked} onChange={(e) => {updateCatalogCheck(catalog._id,e.target.checked)}}></Form.Check>
                                </div>
                                
                            </ListGroup.Item>) : "Not available catalogs.")
                        }
                        </ListGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAddCat}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={updateCatalogs} style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} >
                            Update catalogs
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className='data-div'>
                <ListGroup as="ol" variant='flush'>
                        {
                            (selectedProject?.catalogs && selectedProject?.catalogs?.length !== 0) ? selectedProject?.catalogs.map((catalog) => (
                                <ListGroup.Item
                                    as="li"
                                    className='d-flex justify-content-between align-items-start'
                                    action
                                    key={catalog.name}
                                    onClick={() => {
                                        localStorage.setItem('catalogSelected', catalog._id);
                                        navigate('/catalogs')
                                        setAlertsConfig({
                                            show: true,
                                            variant: "success",
                                            message: JSON.stringify(catalog.useCase)
                                        });
                                    }}
                                >
                                    <div className='ms-2 me-auto'>
                                        <div className='fw-bold'>{catalog.name}</div>
                                        {catalog.description}
                                    </div>
                                    <div style={{width: '20%'}}>
                                        {catalog.currentCounter + "/" + catalog.goal}
                                        <ProgressBar animated now={(catalog.currentCounter/catalog.goal)*100} />
                                    </div>
                                    
                                    
                                </ListGroup.Item>
                            )) : 
                            
                            <ListGroup.Item
                                as='li'
                                className='d-flex align-items-center justify-content-center'
                            ><div>
                                Catalogs not found :(
                            </div>

                            </ListGroup.Item>
                        }
                    </ListGroup>
                </div>
            </div>

            <Modal show={showNewProjectModal} onHide={handleCloseNewProject}>
                <Modal.Header closeButton>
                <Modal.Title>Register new project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text>Project Code</InputGroup.Text>
                            <Form.Control 
                                placeholder='Example: XXX_DYMOS_VX'
                                aria-describedby='basic-addon1'
                                onChange={(event) => {
                                    setNewProject({...newProject, code: event.target.value});
                                }}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text>Project Name</InputGroup.Text>
                            <Form.Control 
                                placeholder='Example: DYDIMOS'
                                aria-describedby='basic-addon1'
                                onChange={(event) => {
                                    setNewProject({...newProject, name: event.target.value});
                                }}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                                <InputGroup.Text>Project Type</InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(event) => setNewProject({...newProject, type: event.target.value})}>
                                    <option>Select one ...</option>
                                    {
                                        project_types ? project_types.map((type) => <option key={type} value={type}>{type}</option>) : <option></option>
                                    }
                                </Form.Select>
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text>Description</InputGroup.Text>
                            <Form.Control 
                                placeholder='Example: Description of the project ...'
                                aria-describedby='basic-addon1'
                                onChange={(event) => {
                                    setNewProject({...newProject, description: event.target.value});
                                }}
                            />
                        </InputGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseNewProject}>
                    Close
                </Button>
                <Button variant="primary" onClick={registerNewProject} style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} >
                    Create project
                </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                className="p-3"
                position="top-end"
                style={{ zIndex: 2 }}
                >
                <Toast onClose={closeAlert} show={alertsConfig.show} bg={alertsConfig.variant}>
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">RESIM 3.0</strong>
                    </Toast.Header>
                    <Toast.Body style={{'color': 'white'}}>{alertsConfig.message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )

}

export default Projects;


