import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import {MaterialReactTable} from "material-react-table";
import { Box } from "@mui/material";
import api_request from "../functions/api_request";
import dateFormatter from "../functions/date_formatter";
import vars from "../vars.json";


function Alerts(props) {

    const [dataLoading, setDataLoading] = useState(false);
    const [logs, setLogs] = useState([]);

    const categorytypes = vars.log.category
    const leveltypes = vars.log.level;
    async function getLogs() {
        setDataLoading(true);
        var logsToShow = [];
        var logResult = await api_request("/logs", 'GET', localStorage.getItem('token'), null);
        if(logResult === false) {
            props.setToken(null);
        } else {
            var newLine;
            for (let i = 0; i < logResult.message.length; i++) {
                newLine = {
                    user: logResult.message[i].user ? logResult.message[i].user.email : "---",
                    category: logResult.message[i].category,
                    level: logResult.message[i].level,
                    video: logResult.message[i].video ? logResult.message[i].video :  "---",
                    timestamp: dateFormatter(logResult.message[i].timestamp),
                    message: logResult.message[i].message
                }
                logsToShow.push(newLine);
            }

            setLogs(logsToShow.reverse());
        }
        setDataLoading(false);
    }

    const log_columns = [
        {
            accessorKey: 'video',
            header: 'Video',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },{
            accessorKey: 'category',
            header: 'Category',
            filterVariant: 'multi-select',
            filterSelectOptions: categorytypes,
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },{
            accessorKey: 'level',
            header: 'Level',
            filterVariant: 'multi-select',
            filterSelectOptions: leveltypes,
            Cell: ({ cell }) => (
                <Box
                  component="span"
                  sx={(theme) => ({
                    backgroundColor:
                      cell.getValue() === "DANGER"
                        ? theme.palette.error.dark
                        : cell.getValue() === "WARNING"
                        ? theme.palette.warning.dark
                        : theme.palette.success.dark,
                    borderRadius: '0.25rem',
                    color: '#fff',
                    maxWidth: '9ch',
                    p: '0.25rem',
                  })}
                >
                    {cell.getValue()}
                </Box>
              ),
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },{
            accessorKey: 'message',
            header: 'Message',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },{
            accessorKey: 'user',
            header: 'User',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },
        {
            accessorKey: 'timestamp',
            header: 'Timestamp',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        }
    ];

    // eslint-disable-next-line 
    useEffect(() => {async function fetchInfo(){await getLogs();} fetchInfo();}, []);

    return(
        <div stle={{padding: '1em'}}>
            <div className='div-for-table'>
                {
                    dataLoading ? <div style={{display: 'flex', justifyContent: 'center', margin: '17vh'}}>
                        <Spinner animation='grow' style={{ color: '#9B820C'}}/>
                    </div> : <div style={{margin: '3em'}}>
                        <MaterialReactTable 
                            columns={log_columns} 
                            data={logs} 
                            state={{density: 'compact'}} 
                            initialState={{pagination: {pageSize: 10, pageIndex: 0}, showColumnFilters: true}} 
                            enableDensityToggle={false} 
                            muiTableBodyCellProps={{sx: {wordBreak: 'break-word', whiteSpace: 'normal'}}} 
                            muiTableProps={{sx: {tableLayout: 'fixed'}}} 
                            defaultColumn={{minSize: 10}} />
                    </div>
                }
            </div>
        </div>
    )
}

export default Alerts;