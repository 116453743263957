import React, { useEffect, useState } from 'react';
import api_request from '../functions/api_request';
import {  Button, Modal, Form, InputGroup, Toast, ToastContainer, Tabs, Tab, Row, Col, Table, Card, ListGroup } from 'react-bootstrap';
import { MaterialReactTable } from 'material-react-table';
import dateFormatter from '../functions/date_formatter';
import { Box } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import EditIcon from '@mui/icons-material/Edit';
import {LineChart} from '@mui/x-charts';
const vars = require("../vars.json");

function Video(props) {

    const [alertsConfig, setAlertsConfig] = useState({
        show: false,
        variant: "success",
        message: "Testing alert ..."
    });
    const [key, setKey] = useState('General');
    const [video, setVideo] = useState({});
    const [videoLogs, setVideoLogs] = useState([]);

    // General
    const [videoGeneral, setVideoGeneral] = useState({});
    const [allVehicles, setAllVehicles] = useState([]);
    const [showEditGeneral, setShowEditGeneral] = useState(false);
    const handleShowEditGeneral = async () => {
        await getAllVehicles();
        setShowEditGeneral(true);
    }
    const handleCloseEditGeneral = () => setShowEditGeneral(false);

    // Context
    const [videoContext, setVideoContext] = useState({});
    const [videoScenario, setVideoScenario] = useState({});
    const [allScenarios, setAllScenarios] = useState([]);

    const [showEditContext, setShowEditContext] = useState(false);
    const handleShowEditContext = () => setShowEditContext(true);
    const handleCloseEditContext = () => setShowEditContext(false);

    const [showEditScenario, setShowEditScenario] = useState(false);
    const handleShowEditScenario = async () => {
        await getAllScenarios();
        setShowEditScenario(true);
    }
    const handleCloseEditScenario = () => setShowEditScenario(false);
    
    // CAN
    const [videoCan, setVideoCan] = useState({});
    const [canX, setCanX] = useState("");
    const [canY, setCanY] = useState("");

    // Checker
    const [videoChecker, setVideoChecker] = useState({});

    // Comments
    const [newComment, setNewComment] = useState({});
    const [showNewComment, setShowNewComment] = useState(false);
    const handleShowNewComment = () => setShowNewComment(true);
    const handleCloseNewComment = () => setShowNewComment(false);

    function closeAlert(){
        setAlertsConfig({...alertsConfig, show: false});
    }

    function updateKey(k) {
        setKey(k);
        if (k === "General"){
            getVideo();
        } else if (k === "Alerts"){
            getLogs()
        } if (k === "CAN"){
            getCanInfo();
        } if (k === "Checker"){
            getChecker();
        }
    }

    // Get video information
    async function getVideo(){
        let dataReceived = await api_request('/video/'+localStorage.getItem('videoPath'), 'GET', localStorage.getItem('token'), null);
        if (dataReceived === false){
            props.setToken(null);
        } else {
            setVideo(dataReceived.message);
        }
    }


    // Comments
    function editNewComment(){
        handleShowNewComment();
        setNewComment({
            video: localStorage.getItem('videoId')
        });
    }
    async function updateNewComment(){
        let updateComment = await api_request('/video/comment', 'POST', localStorage.getItem('token'), JSON.stringify(newComment));
        if (updateComment === false) {
            props.setToken(null);
        } else {
            if (updateComment.error === false) {
                setAlertsConfig({
                    show: true,
                    variant: "success",
                    message:"New comment added correctly!"
                });
                getVideo()
            } else {
                setAlertsConfig({
                    show: true,
                    variant: "danger",
                    message:"Error posting comment: " + updateComment.message
                });
            }
            handleCloseNewComment();
        }
    }
    
    // General
    async function getAllVehicles(){
        var dataReceived = await api_request("/vehicles", 'GET', localStorage.getItem('token'), null);
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            setAllVehicles(dataReceived.message);
        }
    }

    async function updateGeneral(){
        
        var videoToUpdate = {...videoGeneral, path: video.path};
        if (videoGeneral.egoVehicle){
            for (let i = 0; i < allVehicles.length; i++){
                if (allVehicles[i].plate === videoGeneral.egoVehicle){
                    videoToUpdate.egoVehicle = allVehicles[i]._id;
                }
            }
        }
        let updated = await api_request('/video', 'POST', localStorage.getItem('token'), JSON.stringify(videoToUpdate));
        if (updated === false) {
            props.setToken(null);
        } else {
            if (updated.error === false) {
                setAlertsConfig({
                    show: true,
                    variant: "success",
                    message:"General variables updated correctly!"
                });
                getVideo()
            } else {
                setAlertsConfig({
                    show: true,
                    variant: "danger",
                    message:"Error updating general variables: " + updated.message
                });
            }
            handleCloseEditGeneral();
        }
    }

    // Context
    async function updateContext(){
        var videoToUpdate = {
            path: video.path,
            context: videoContext
        };
        let updated = await api_request('/video', 'POST', localStorage.getItem('token'), JSON.stringify(videoToUpdate));
        if (updated === false) {
            props.setToken(null);
        } else {
            if (updated.error === false) {
                setAlertsConfig({
                    show: true,
                    variant: "success",
                    message:"Context updated correctly!"
                });
                getVideo()
            } else {
                setAlertsConfig({
                    show: true,
                    variant: "danger",
                    message:"Error updating context: " + updated.message
                });
            }
            handleCloseEditContext();
        }

    }

    async function getAllScenarios(){
        var dataReceived = await api_request("/scenarios", 'GET', localStorage.getItem('token'), null);
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            setAllScenarios(dataReceived.message);
        }
    }

    async function updateScenario(){
        var newScenarioId;
        for (let i = 0; i < allScenarios.length; i++){
            if(videoScenario === allScenarios[i].name){
                    newScenarioId = allScenarios[i]._id;
            }
        }

        if(newScenarioId){
            var videoToUpdate = {
                path: video.path,
                scenario: newScenarioId
            };
            let updated = await api_request('/video', 'POST', localStorage.getItem('token'), JSON.stringify(videoToUpdate));
            if (updated === false) {
                props.setToken(null);
                return;
            } else {
                if (updated.error === false) {
                    setAlertsConfig({
                        show: true,
                        variant: "success",
                        message:"Scenario updated correctly!"
                    });
                } else {
                    setAlertsConfig({
                        show: true,
                        variant: "danger",
                        message:"Error updating scenario: " + updated.message
                    });
                }
            }
        } 
        getVideo();
        handleCloseEditScenario();
    }
    
    // CAN
    async function getCanInfo(){
        let canReceived = await api_request('/can?video='+localStorage.getItem('videoId'), 'GET', localStorage.getItem('token'), null);
        if (canReceived === false){
            props.setToken(null);
        } else {
            setVideoCan(canReceived.message.data);
        }
    }
    function setXAxis(event){
        setCanX(event.target.id)
    }
    function setYAxis(event){
        setCanY(event.target.id)
    }
    const average = arr => Math.round(100*(arr.reduce( ( p, c ) => p + c, 0 ) / arr.length))/100;

    // Checker
    async function getChecker(){
        let checkerRec = await api_request('/checker?video='+localStorage.getItem('videoId'), 'GET', localStorage.getItem('token'), null);
        if (checkerRec === false){
            props.setToken(null);
        } else {
            setVideoChecker(checkerRec.message);
        }
    }

    // Alerts
    async function getLogs(){
        let logResult = await api_request('/logs?video='+localStorage.getItem('videoPath'), 'GET', localStorage.getItem('token'), null);
        if (logResult === false){
            props.setToken(null);
        } else {
            var newLine;
            var logsToShow = [];
            for (let i = 0; i < logResult.message.length; i++) {
                newLine = {
                    user: logResult.message[i].user ? logResult.message[i].user.email : "---",
                    category: logResult.message[i].category,
                    level: logResult.message[i].level,
                    video: logResult.message[i].video ? logResult.message[i].video :  "---",
                    timestamp: dateFormatter(logResult.message[i].timestamp),
                    message: logResult.message[i].message
                }
                logsToShow.push(newLine);
            }

            setVideoLogs(logsToShow.reverse());
        }
    }
    const log_columns = [
        {
            accessorKey: 'timestamp',
            header: 'Timestamp',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },
        {
            accessorKey: 'category',
            header: 'Category',
            filterVariant: 'multi-select',
            filterSelectOptions: vars.log.category,
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },{
            accessorKey: 'level',
            header: 'Level',
            filterVariant: 'multi-select',
            filterSelectOptions: vars.log.level,
            Cell: ({ cell }) => (
                <Box
                  component="span"
                  sx={(theme) => ({
                    backgroundColor:
                      cell.getValue() === "DANGER"
                        ? theme.palette.error.dark
                        : cell.getValue() === "WARNING"
                        ? theme.palette.warning.dark
                        : theme.palette.success.dark,
                    borderRadius: '0.25rem',
                    color: '#fff',
                    maxWidth: '9ch',
                    p: '0.25rem',
                  })}
                >
                    {cell.getValue()}
                </Box>
              ),
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },{
            accessorKey: 'message',
            header: 'Message',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },{
            accessorKey: 'user',
            header: 'User',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        }
    ];


    // eslint-disable-next-line 
    useEffect(() => {async function fetchInfo(){await getVideo();} fetchInfo();}, []);


    return(
        <div stle={{padding: '1em'}}>
           <div className='div-for-table'>
                <div style={{padding: '3em'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h3><VideocamIcon sx={{ fontSize: 40 }} style={{paddingBottom:'8px'}}/>{"   " +localStorage.getItem('videoPath')}</h3>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                           <Button onClick={editNewComment} style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}}>New comment</Button>
                        </div>
                    </div>
                    <Tabs
                        id="controlled-tab"
                        activeKey={key}
                        onSelect={(k) => updateKey(k)}
                        className="mb-3"
                        fill
                        style={{marginTop: '2em'}}
                    >
                        <Tab eventKey="General" title="General">
                            <div>
                                <Row>
                                    <Col>
                                        <div className="info-container">
                                            <Table borderless>
                                                <tbody>
                                                    <tr>
                                                        <td><b>Path</b></td>
                                                        <td>{video.path}</td>
                                                        <td style={{display: 'flex', justifyContent: 'flex-end'}}><Button onClick={handleShowEditGeneral} style={{padding: 0, backgroundColor: '#9B820C', borderColor: '#9B820C'}}><EditIcon></EditIcon></Button></td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>XML version</b></td>
                                                        <td>{video.xmlVersion ? video.xmlVersion : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Status</b></td>
                                                        <td>{video.status ? video.status : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>EGO Vehicle</b></td>
                                                        <td>{video.egoVehicle ? video.egoVehicle.plate + "   (" + video.egoVehicle.brand + " " + video.egoVehicle.model + ")" : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Recorded at</b></td>
                                                        <td>{video.recordedAt ? dateFormatter(video.recordedAt) : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Recorded by</b></td>
                                                        <td>{video.recordedBy ? video.recordedBy.email + " (" + video.recordedBy.role + ")" : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Updated at</b></td>
                                                        <td>{video.updatedAt ? dateFormatter(video.updatedAt) : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Updated by</b></td>
                                                        <td>{video.updatedBy ? video.updatedBy.email + " (" + video.updatedBy.role + ")" : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Created at</b></td>
                                                        <td>{video.createdAt ? dateFormatter(video.createdAt) : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Created by</b></td>
                                                        <td>{video.createdBy ? video.createdBy.email + " (" + video.createdBy.role + ")" : "-"}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="info-container">
                                                {
                                                    (video?.comments && video.comments?.length > 0) ? video.comments.slice().reverse().map((comment) => (
                                                        <Card key={comment?._id} style={{padding: '1em', margin: '1em'}}>
                                                            <Card.Header>{comment?.user?.email + " - " + dateFormatter(comment?.timestamp)}</Card.Header>
                                                            <Card.Body><Card.Text>{comment?.message}</Card.Text></Card.Body>
                                                        </Card>
                                                    )) : <div><p>There are no comments yet.</p></div>
                                                }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <Modal show={showEditGeneral} onHide={handleCloseEditGeneral}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit Genereal</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>XML version</InputGroup.Text>
                                        <Form.Control 
                                            placeholder={video.xmlVersion}
                                            defaultValue={video.xmlVersion}
                                            aria-describedby='basic-addon1'
                                            onChange={(event) => {
                                                setVideoGeneral({...videoGeneral, xmlVersion: event.target.value});
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Status</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.status} onChange={(event) => setVideoGeneral({...videoGeneral, status: event.target.value})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.video.status ? vars.video.status.map((status) => <option key={status} value={status}>{status}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>EGO Vehicle</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.egoVehicle?.plate} onChange={(event) => {console.log(event.target.value); setVideoGeneral({...videoGeneral, egoVehicle: event.target.value})}}>
                                            <option>Select one ...</option>
                                            {
                                                allVehicles ? allVehicles.map((ego) => <option key={ego.plate} name={ego._id} value={ego.plate}>{ego.plate}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseEditGeneral}>Close</Button>
                                    <Button variant="primary" style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} onClick={updateGeneral} >
                                        Update general
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Tab>   
                        <Tab eventKey="Context" title="Context & Scenario">
                            <div>
                                <Row>
                                    <Col>
                                        <div className='info-container'>
                                            <h5>Context</h5>
                                            <Table borderless>
                                                <tbody>
                                                    <tr>
                                                        <td><b>Season</b></td>
                                                        <td>{video?.context?.season ? video?.context?.season : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Country</b></td>
                                                        <td>{video?.context?.country ? video.context.country : "-"}</td>
                                                        <td style={{display: 'flex', justifyContent: 'flex-end'}}><Button onClick={handleShowEditContext} style={{padding: 0, backgroundColor: '#9B820C', borderColor: '#9B820C'}}><EditIcon></EditIcon></Button></td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Road Type</b></td>
                                                        <td>{video?.context?.road?.type ? video.context.road.type : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Road Surface Material</b></td>
                                                        <td>{video?.context?.road?.surfaceMaterial ? video.context.road.surfaceMaterial : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Road Surface Condition</b></td>
                                                        <td>{video?.context?.road?.surfaceCondition ? video.context.road.surfaceCondition : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Light</b></td>
                                                        <td>{video?.context?.light ? video.context.light : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Weather</b></td>
                                                        <td>{video?.context?.weather ? video.context.weather : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Speed Range</b></td>
                                                        <td>{video?.context?.speedRange ? video.context.speedRange : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Sky Cover</b></td>
                                                        <td>{video?.context?.skyCover ? video.context.skyCover : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Soil Type</b></td>
                                                        <td>{video?.context?.soilType ? video.context.soilType : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Soil Coverage</b></td>
                                                        <td>{video?.context?.soilCoverage ? video.context.soilCoverage : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Glare Type</b></td>
                                                        <td>{video?.context?.glareType ? video.context.glareType : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Recording Type</b></td>
                                                        <td>{video?.context?.recordingType ? video.context.recordingType : "-"}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='info-container'>
                                            <h5>Scenario</h5>
                                            <Table borderless>
                                                <tbody>
                                                    <tr>
                                                        <td><b>Name</b></td>
                                                        <td>{video?.scenario?.name ? video.scenario.name : "-"}</td>
                                                        <td style={{display: 'flex', justifyContent: 'flex-end'}}><Button onClick={handleShowEditScenario} style={{padding: 0, backgroundColor: '#9B820C', borderColor: '#9B820C'}}><EditIcon></EditIcon></Button></td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Type</b></td>
                                                        <td>{video?.scenario?.type ? video.scenario.type : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Location</b></td>
                                                        <td>Latitude</td>
                                                        <td>{video?.scenario?.location?.lat ? video.scenario.location.lat : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Longitude</td>
                                                        <td>{video?.scenario?.location?.lon ? video.scenario.location.lon : "-"}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        
                                    </Col>
                                </Row>
                            </div>
                            <Modal show={showEditContext} onHide={handleCloseEditContext}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit Context</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Season</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.season} onChange={(event) => setVideoContext({...videoContext, season: event.target.value})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.season ? vars.context.season.map((season) => <option key={season} value={season}>{season}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Country</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.country} onChange={(event) => setVideoContext({...videoContext, country: event.target.value})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.countries ? vars.context.countries.map((country) => <option key={country} value={country}>{country}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Road Type</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.road?.type} onChange={(event) => setVideoContext({...videoContext, road: {...videoContext.road, type: event.target.value}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.road.type ? vars.context.road.type.map((type) => <option key={type} value={type}>{type}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Road Surface Material</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.road?.surfaceMaterial} onChange={(event) => setVideoContext({...videoContext, road: {...videoContext.road, surfaceMaterial: event.target.value}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.road.surfaceMaterial ? vars.context.road.surfaceMaterial.map((mat) => <option key={mat} value={mat}>{mat}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Road Surface Condition</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.road?.surfaceCondition} onChange={(event) => setVideoContext({...videoContext, road: {...videoContext.road, surfaceCondition: event.target.value}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.road.surfaceCondition ? vars.context.road.surfaceCondition.map((cond) => <option key={cond} value={cond}>{cond}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Light</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.light} onChange={(event) => setVideoContext({...videoContext, light: event.target.value})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.light ? vars.context.light.map((light) => <option key={light} value={light}>{light}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Weather</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.weather} onChange={(event) => setVideoContext({...videoContext, weather: event.target.value})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.weather ? vars.context.weather.map((weather) => <option key={weather} value={weather}>{weather}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Speed Range</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.speedRange} onChange={(event) => setVideoContext({...videoContext, speedRange: event.target.value})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.speedRange ? vars.context.speedRange.map((speedRange) => <option key={speedRange} value={speedRange}>{speedRange}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Sky Cover</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.skyCover} onChange={(event) => setVideoContext({...videoContext, skyCover: event.target.value})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.skyCover ? vars.context.skyCover.map((skyCover) => <option key={skyCover} value={skyCover}>{skyCover}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Soil Type</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.soilType} onChange={(event) => setVideoContext({...videoContext, soilType: event.target.value})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.soilType ? vars.context.soilType.map((soilType) => <option key={soilType} value={soilType}>{soilType}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Soil Coverage</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.soilCoverage} onChange={(event) => setVideoContext({...videoContext, soilCoverage: event.target.value})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.soilCoverage ? vars.context.soilCoverage.map((soilCoverage) => <option key={soilCoverage} value={soilCoverage}>{soilCoverage}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Glare Type</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.glareType} onChange={(event) => setVideoContext({...videoContext, glareType: event.target.value})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.glareType ? vars.context.glareType.map((glareType) => <option key={glareType} value={glareType}>{glareType}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Recording Type</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.context?.recordingType} onChange={(event) => setVideoContext({...videoContext, recordingType: event.target.value})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.recordingType ? vars.context.recordingType.map((recordingType) => <option key={recordingType} value={recordingType}>{recordingType}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseEditContext}>Close</Button>
                                    <Button variant="primary" style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} onClick={updateContext} >
                                        Update context
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={showEditScenario} onHide={handleCloseEditScenario}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit Scenario</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Scenario</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" defaultValue={video?.scenario?.name} onChange={(event) => setVideoScenario(event.target.value)}>
                                            <option>Select one ...</option>
                                            {
                                                allScenarios ? allScenarios.map((scenario) => <option key={scenario.name} value={scenario.name}>{scenario.name}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseEditScenario}>Close</Button>
                                    <Button variant="primary" style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} onClick={updateScenario} >
                                        Update scenario
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Tab>
                        <Tab eventKey="CAN" title="CAN">
                        <div style={{display: 'flex'}}>
                            <div className='mini-info-container'>
                                <h5>Plot variables</h5>
                                <Form onChange={setYAxis}>
                                    <Form.Check
                                        type="radio"
                                        id="velocity"
                                        label="Velocity [km/h]"
                                        name="yaxis"
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="yaw_angle"
                                        label="Yaw Angle [º/s]"
                                        name="yaxis"
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="steering_wheel"
                                        label="Steering Wheel [º/s]"
                                        name="yaxis"
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="wheel_speed_front_left"
                                        label="Wheel Speed Front Left"
                                        name="yaxis"
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="wheel_speed_front_right"
                                        label="Wheel Speed Front Right"
                                        name="yaxis"
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="wheel_speed_back_left"
                                        label="Wheel Speed Back Left"
                                        name="yaxis"
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="wheel_speed_back_right"
                                        label="Wheel Speed Back Right"
                                        name="yaxis"
                                    />
                                </Form>

                                <h5>X Axis</h5>

                                <Form onChange={setXAxis}>
                                    <Form.Check
                                        type="radio"
                                        id="frame"
                                        label="Frame"
                                        name="xaxis"
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="timestamp"
                                        label="Timestamp"
                                        name="xaxis"
                                    />

                                </Form>
                            </div>
                            <div className='graph-container'>
                                {
                                    (canX !== "" && canY !== "" && videoCan?.can) ?
                                    <LineChart
                                        width={1000}
                                        height={300}
                                        series={[
                                            { data: videoCan['can'][canY], label: canY.toUpperCase(), color: "#9B820C", showMark: false }                                        ]}
                                        
                                        xAxis={[{ scaleType: 'point', data: videoCan[canX] }]}
                                    /> : <div>Please select the variable and the X Axis. If not working, probably there is no CAN information.</div>
                                }
                                {
                                    (canX !== "" && canY !== "" && videoCan?.can) ? 
                                    <div>
                                        <p><b>Max:</b>  {Math.max(...videoCan['can'][canY])}</p>
                                        <p><b>Min:</b>  {Math.min(...videoCan['can'][canY])}</p>
                                        <p><b>Mean:</b>  {average(videoCan['can'][canY])}</p>
                                    </div> : <div></div>
                                }
                                
                            </div>
                        </div>
                        </Tab>
                        <Tab eventKey="KPI" title="KPI">
                        </Tab>
                        <Tab eventKey="Checker" title="Checker">
                            <div>
                            <Row>
                                <Col>
                                    <div className='info-container'>
                                        <h5>Sensors</h5>
                                        <ListGroup as="ol" variant='flush'>
                                            {
                                                (videoChecker?.sensors && videoChecker?.sensors?.length !== 0) ? videoChecker?.sensors.map((sensor) => (
                                                    <ListGroup.Item
                                                        as="li"
                                                        className='d-flex justify-content-between align-items-start'
                                                        action
                                                        key={sensor.sensorId}
                                                    >
                                                        <div className='ms-2 me-auto'>
                                                            <div className='fw-bold'>{"(" + (sensor.sensorNumber ? sensor.sensorNumber : "-") + ") " + (sensor.name ? sensor.name : "-")}</div>
                                                            ({(sensor.signal ? sensor.signal : "-")})
                                                        </div>
                                                        {(sensor.sensorId ? sensor.sensorId : "-")}
                                                        
                                                    </ListGroup.Item>
                                                )) : 
                                                
                                                <ListGroup.Item
                                                    as='li'
                                                    className='d-flex align-items-center justify-content-center'
                                                ><div>
                                                    Sensors not found :(
                                                </div>

                                                </ListGroup.Item>
                                            }
                                        </ListGroup>
                                        
                                    </div>
                                    
                                </Col>
                                <Col>
                                    <div className='info-container'>
                                        <Table borderless>
                                            <tbody>
                                                <tr>
                                                    <td><b>Lidar</b></td>
                                                    <td><b>Sync Mean</b></td>
                                                    <td>{videoChecker?.metrics?.lidar_sync_mean ? videoChecker?.metrics?.lidar_sync_mean + " μs" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b></b></td>
                                                    <td><b>Sync Error Max</b></td>
                                                    <td>{videoChecker?.metrics?.lidar_sync_error_max ? videoChecker?.metrics?.lidar_sync_error_max + " μs" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b></b></td>
                                                    <td><b>Sync Error Min</b></td>
                                                    <td>{videoChecker?.metrics?.lidar_sync_error_min ? videoChecker?.metrics?.lidar_sync_error_min + " μs" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b></b></td>
                                                    <td><b>Timediff Mean</b></td>
                                                    <td>{videoChecker?.metrics?.lidar_timediff_mean ? videoChecker?.metrics?.lidar_timediff_mean + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b></b></td>
                                                    <td><b>Timediff Max</b></td>
                                                    <td>{videoChecker?.metrics?.lidar_timediff_max ? videoChecker?.metrics?.lidar_timediff_max + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b></b></td>
                                                    <td><b>Timediff Min</b></td>
                                                    <td>{videoChecker?.metrics?.lidar_timediff_min ? videoChecker?.metrics?.lidar_timediff_min + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b></b></td>
                                                    <td><b>Timediff Std</b></td>
                                                    <td>{videoChecker?.metrics?.lidar_timediff_std ? videoChecker?.metrics?.lidar_timediff_std + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>dGPS</b></td>
                                                    <td><b>Sync Success</b></td>
                                                    <td>{videoChecker?.metrics?.dgps_sync_succ ? videoChecker?.metrics?.dgps_sync_succ + " μs" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b></b></td>
                                                    <td><b>Sync Success Max</b></td>
                                                    <td>{videoChecker?.metrics?.dgps_sync_succ_max ? videoChecker?.metrics?.dgps_sync_succ_max + " μs" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b></b></td>
                                                    <td><b>Sync Success Min</b></td>
                                                    <td>{videoChecker?.metrics?.dgps_sync_succ_min ? videoChecker?.metrics?.dgps_sync_succ_min + " μs" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b></b></td>
                                                    <td><b>North Mean</b></td>
                                                    <td>{videoChecker?.metrics?.dgps_north_media ? videoChecker?.metrics?.dgps_north_media + " mm" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b></b></td>
                                                    <td><b>East Mean</b></td>
                                                    <td>{videoChecker?.metrics?.dgps_east_media ? videoChecker?.metrics?.dgps_east_media + " mm" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b><u>CAM 0</u></b></td>
                                                    <td><b>Timediff Mean</b></td>
                                                    <td>{videoChecker?.metrics?.cam_0_timediff_mean ? videoChecker?.metrics?.cam_0_timediff_mean + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Timediff Max</b></td>
                                                    <td>{videoChecker?.metrics?.cam_0_timediff_max ? videoChecker?.metrics?.cam_0_timediff_max + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Timediff Min</b></td>
                                                    <td>{videoChecker?.metrics?.cam_0_timediff_min ? videoChecker?.metrics?.cam_0_timediff_min + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Timediff Std</b></td>
                                                    <td>{videoChecker?.metrics?.cam_0_timediff_std ? videoChecker?.metrics?.cam_0_timediff_std + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b><u>CAM 1</u></b></td>
                                                    <td><b>Timediff Mean</b></td>
                                                    <td>{videoChecker?.metrics?.cam_1_timediff_mean ? videoChecker?.metrics?.cam_1_timediff_mean + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Timediff Max</b></td>
                                                    <td>{videoChecker?.metrics?.cam_1_timediff_max ? videoChecker?.metrics?.cam_1_timediff_max + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Timediff Min</b></td>
                                                    <td>{videoChecker?.metrics?.cam_1_timediff_min ? videoChecker?.metrics?.cam_1_timediff_min + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Timediff Std</b></td>
                                                    <td>{videoChecker?.metrics?.cam_1_timediff_std ? videoChecker?.metrics?.cam_1_timediff_std + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b><u>CAM 2</u></b></td>
                                                    <td><b>Timediff Mean</b></td>
                                                    <td>{videoChecker?.metrics?.cam_2_timediff_mean ? videoChecker?.metrics?.cam_2_timediff_mean + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Timediff Max</b></td>
                                                    <td>{videoChecker?.metrics?.cam_2_timediff_max ? videoChecker?.metrics?.cam_2_timediff_max + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Timediff Min</b></td>
                                                    <td>{videoChecker?.metrics?.cam_2_timediff_min ? videoChecker?.metrics?.cam_2_timediff_min + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Timediff Std</b></td>
                                                    <td>{videoChecker?.metrics?.cam_2_timediff_std ? videoChecker?.metrics?.cam_2_timediff_std + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b><u>CAM 3</u></b></td>
                                                    <td><b>Timediff Mean</b></td>
                                                    <td>{videoChecker?.metrics?.cam_3_timediff_mean ? videoChecker?.metrics?.cam_3_timediff_mean + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Timediff Max</b></td>
                                                    <td>{videoChecker?.metrics?.cam_3_timediff_max ? videoChecker?.metrics?.cam_3_timediff_max + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Timediff Min</b></td>
                                                    <td>{videoChecker?.metrics?.cam_3_timediff_min ? videoChecker?.metrics?.cam_3_timediff_min + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Timediff Std</b></td>
                                                    <td>{videoChecker?.metrics?.cam_3_timediff_std ? videoChecker?.metrics?.cam_3_timediff_std + " ms" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                </tr>
                                                <tr>
                                                    <td><b>Updated at</b></td>
                                                    <td>{videoChecker.updatedAt ? dateFormatter(videoChecker.updatedAt) : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Updated by</b></td>
                                                    <td>{videoChecker.updatedBy ? videoChecker.updatedBy.email + " (" + videoChecker.updatedBy.role + ")" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Created at</b></td>
                                                    <td>{videoChecker.createdAt ? dateFormatter(videoChecker.createdAt) : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Created by</b></td>
                                                    <td>{videoChecker.createdBy ? videoChecker.createdBy.email + " (" + videoChecker.createdBy.role + ")" : "-"}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            </div>
                        </Tab>
                        <Tab eventKey="Alerts" title="Alerts">
                                <MaterialReactTable 
                                    columns={log_columns} 
                                    data={videoLogs} 
                                    state={{density: 'compact'}} 
                                    initialState={{pagination: {pageSize: 10, pageIndex: 0}, showColumnFilters: true}} 
                                    enableDensityToggle={false} 
                                    muiTableBodyCellProps={{sx: {wordBreak: 'break-word', whiteSpace: 'normal'}}} 
                                    muiTableProps={{sx: {tableLayout: 'fixed'}}} 
                                    defaultColumn={{minSize: 10}} />
                        </Tab>
                    </Tabs>
                </div>
            </div>

            <Modal show={showNewComment} onHide={handleCloseNewComment}>
                <Modal.Header closeButton>
                <Modal.Title>New comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text>Message</InputGroup.Text>
                            <Form.Control 
                                placeholder={ "Introduce message ..."}
                                aria-describedby='basic-addon1'
                                onChange={(event) => {
                                    setNewComment({...newComment, comment: event.target.value});
                                }}
                            />
                        </InputGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseNewComment}>
                    Close
                </Button>
                <Button variant="primary" style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} onClick={updateNewComment} >
                    Update comment
                </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                className="p-3"
                position="top-end"
                style={{ zIndex: 2 }}
                >
                <Toast onClose={closeAlert} show={alertsConfig.show} bg={alertsConfig.variant}>
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">RESIM 3.0</strong>
                    </Toast.Header>
                    <Toast.Body style={{'color': 'white'}}>{alertsConfig.message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )

}

export default Video;


