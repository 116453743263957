import {  useEffect, useState } from 'react';
import api_request from '../functions/api_request';
import { ListGroup, Button, Modal, Form, InputGroup, Toast, ToastContainer, Accordion } from 'react-bootstrap';
import { MaterialReactTable } from 'material-react-table';
import dateFormatter from '../functions/date_formatter';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import  DeleteIcon from '@mui/icons-material/Delete';
import JsonView from '@uiw/react-json-view';
const vars = require("../vars.json");

function Catalogs(props) {

    const [alertsConfig, setAlertsConfig] = useState({
        show: false,
        variant: "success",
        message: "Testing alert ..."
    });
    const [catalogList, setCatalogList] = useState([]);
    const [selectedCatalog, setSelectedCatalog] = useState({});

    async function getCatalogVideos(){
        var dataReceived = await api_request("/catalog/" + localStorage.getItem('catalogSelected') + "/videos", 'GET', localStorage.getItem('token'), null);
        if (dataReceived === false){
            props.setToken(null);
        } else {
            let newSelProject = dataReceived.message;
            for (let i = 0; i < newSelProject?.videos?.length; i++){
                newSelProject.videos[i].updatedAt = newSelProject.videos[i].updatedAt ? dateFormatter(newSelProject.videos[i].updatedAt) : "-";
                newSelProject.videos[i].recordedAt = newSelProject.videos[i].recordedAt ? dateFormatter(newSelProject.videos[i].recordedAt) : "-";
            }
            await setSelectedCatalog(newSelProject);
        }
    }
    
    async function getCatalogs(){
        var dataReceived = await api_request("/catalogs", 'GET', localStorage.getItem('token'), null);
        if (dataReceived === false){
            props.setToken(null);
        } else {
            let newDataCatalogs = dataReceived.message;
            await setSelectedCatalog(newDataCatalogs[0]);
            await getCatalogVideos();
            setCatalogList(newDataCatalogs);
        }
    }

    async function filterByName(str){
        await getCatalogs();
        if(str !== ""){
            var newCatalogList = [];
            console.log(str);
            for (let i=0; i < catalogList.length; i++){
                if (catalogList[i].name.toLowerCase().indexOf(str) !== -1){
                    newCatalogList.push(catalogList[i]);
                }
            }
            await setCatalogList(newCatalogList);
        }
        if(catalogList.length === 0) {
            await getCatalogs();
        }
    }

    async function deleteCatalog(){
        var dataReceived = await api_request("/catalog", 'DELETE', localStorage.getItem('token'), JSON.stringify({"catalog": selectedCatalog._id}));
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            if (dataReceived.error){
                setAlertsConfig({
                    show: true,
                    variant: "danger",
                    message:"Error deleting catalog: " + dataReceived.message
                });
            } else {
                setAlertsConfig({
                    show: true,
                    variant: "success",
                    message:"Catalog deleted correctly!"
                });
            }
            getCatalogs();
            setNewCatalog({});
        }
    }

    // New catalog
    const [allVehicles, setAllVehicles] = useState([]);
    const [showNewCatalog, setShowNewCatalog] = useState(false);
    const handleShowNewCatalog = async () => {
        await getAllVehicles();
        setShowNewCatalog(true);
    }
    const handleCloseNewCatalog = () => setShowNewCatalog(false);
    const [newCatalog, setNewCatalog] = useState({
        name: "",
        description: "",
        goal: 0,
        useCase: {}
    });

    async function getAllVehicles(){
        var dataReceived = await api_request("/vehicles", 'GET', localStorage.getItem('token'), null);
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            setAllVehicles(dataReceived.message);
        }
    }

    async function createCatalog(){

        var dataReceived = await api_request("/catalog/new", 'POST', localStorage.getItem('token'), JSON.stringify(newCatalog));
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            if (dataReceived.error){
                setAlertsConfig({
                    show: true,
                    variant: "danger",
                    message:"Error registering catalog: " + dataReceived.message
                });
            } else {
                setAlertsConfig({
                    show: true,
                    variant: "success",
                    message:"Catalog registered correctly!"
                });
            }
            getCatalogs();
            setNewCatalog({});
            handleCloseNewCatalog();
        }
    }

    // Edit catalog
    const [catalogUpdate, setCatalogUpdate] = useState({});
    const [showEditCatalog, setShowEditCatalog] = useState(false);
    const handleShowEditCatalog = async () => {
        var catName = selectedCatalog.name;
        setCatalogUpdate({...catalogUpdate, name: catName});
        setShowEditCatalog(true);
    }
    const handleCloseEditCatalog = () => setShowEditCatalog(false);

    async function updateCatalog(){
        var dataReceived = await api_request("/catalog", 'POST', localStorage.getItem('token'), JSON.stringify(catalogUpdate));
        if (dataReceived === false) {
            props.setToken(null);
        } else {
            if (dataReceived.error){
                setAlertsConfig({
                    show: true,
                    variant: "danger",
                    message:"Error updating catalog: " + dataReceived.message
                });
            } else {
                setAlertsConfig({
                    show: true,
                    variant: "success",
                    message:"Catalog updated correctly!"
                });
            }
            await getCatalogs();
            setCatalogUpdate({});
            handleCloseEditCatalog();
        }
    }

    // JSON use case
    const [showUseCase, setShowUseCase] = useState(false);
    const handleShowUseCase = () => setShowUseCase(true);
    const handleCloseUseCase = () => setShowUseCase(false);

    const videoListColumns = [
        {
            accessorKey: 'path',
            header: 'Video Name',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },{
            accessorKey: 'status',
            header: 'Status',
            filterVariant: 'multi-select',
            Cell: ({ cell }) => (
                <Box
                  component="span"
                  sx={(theme) => ({
                    backgroundColor:
                      cell.getValue() === "HALTED"
                        ? theme.palette.error.light
                        : cell.getValue() === "PENDING"
                        ? theme.palette.warning.light
                        : theme.palette.success.light,
                    borderRadius: '0.25rem',
                    color: '#fff',
                    maxWidth: '9ch',
                    p: '0.25rem',
                  })}
                >
                    {cell.getValue()}
                </Box>
              ),
            filterSelectOptions: vars.video.status,
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },
        {
            accessorKey: 'recordedAt',
            header: 'Recording Date',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },{
            accessorKey: 'updatedAt',
            header: 'Update Date',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        }
    ];
    
    function closeAlert(){
        setAlertsConfig({...alertsConfig, show: false});
    }

    // eslint-disable-next-line 
    useEffect(() => {async function fethCatalogs(){await getCatalogs()} fethCatalogs();}, []);


    return(
        <div stle={{padding: '6em', paddingTop: '3em'}}>
           <div className='left-column'>
                <div className='title-left'>
                    <h5>CATALOGS</h5>
                    <hr style={{margin: '.5em', marginTop: 0, color: '#9B820C', opacity: '1'}}></hr>
                    <div style ={{margin: '.5em', overflow: 'auto', height: '60vh'}}>
                        <InputGroup className="mb-3 mt-3">
                            <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
                            <Form.Control
                            placeholder="Filter by name ..."
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={(event) => {filterByName(event.target.value)}}
                            />
                        </InputGroup>
                        <ListGroup as="ol" variant='flush'>
                            {
                                catalogList.length !== 0 ? catalogList.map((catalog) => (
                                    <ListGroup.Item
                                    as="li"
                                    className='d-flex justify-content-between align-items-start'
                                    action
                                    key={catalog.name}
                                    onClick={() => {
                                        localStorage.setItem('catalogSelected', catalog._id);
                                        getCatalogVideos();
                                    }}
                                >
                                    <div className='ms-2 me-auto' style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                        <div className='fw-bold'>{catalog.name}</div>
                                        <div>{catalog.description}</div>
                                    </div>
                                    {catalog.currentCounter + "/" + catalog.goal}
                                </ListGroup.Item>)) : 
                                <ListGroup.Item as='li' className='d-flex align-items-center justify-content-center'>
                                    <div>Projects not found :(</div>
                                </ListGroup.Item>
                            }
                        </ListGroup>
                    </div>
                    <div style={{margin: '1.5em', display: 'flex', justifyContent: 'center'}}><Button style={{ backgroundColor: '#9B820C', borderColor: '#9B820C'}} onClick={handleShowNewCatalog}>NEW CATALOG</Button></div>
                </div>


                <Modal show={showNewCatalog} onHide={handleCloseNewCatalog}>
                    <Modal.Header closeButton>
                    <Modal.Title>Create catalog</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text>Catalog Name</InputGroup.Text>
                        <Form.Control
                            placeholder="Introduce catalog's name ..."
                            aria-describedby='basic-addon1'
                            onChange={(event) => setNewCatalog({...newCatalog, name: event.target.value})}
                        />
                    </InputGroup>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text>Description</InputGroup.Text>
                        <Form.Control
                            placeholder="Short description for catalog ..."
                            aria-describedby='basic-addon1'
                            onChange={(event) => setNewCatalog({...newCatalog, description: event.target.value})}
                        />
                    </InputGroup>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text>Goal</InputGroup.Text>
                        <Form.Control
                            placeholder="Goal number of videos ..."
                            aria-describedby='basic-addon1'
                            onChange={(event) => setNewCatalog({...newCatalog, goal: event.target.value})}
                        />
                    </InputGroup>
                    <hr></hr>
                    <h5>Use Case - Filters</h5>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>General</Accordion.Header>
                            <Accordion.Body>
                                <InputGroup className='mb-3'>
                                        <InputGroup.Text>Video Status</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, status: event.target.value}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.video.status ? vars.video.status.map((status) => <option key={status} value={status}>{status}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                </InputGroup>
                                
                                <InputGroup className='mb-3'>
                                        <InputGroup.Text>EGO Vehicle</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, egoVehicle: {...newCatalog?.useCase?.egoVehicle, plate: event.target.value}}})}>
                                            <option>Select one ...</option>
                                            {
                                                allVehicles ? allVehicles.map((ego) => <option key={ego.plate} name={ego._id} value={ego.plate}>{ego.plate}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Context</Accordion.Header>
                            <Accordion.Body>
                            <InputGroup className='mb-3'>
                            <InputGroup className='mb-3'>
                                    <InputGroup.Text>Season</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog?.useCase?.context, season: event.target.value}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.season ? vars.context.season.map((season) => <option key={season} value={season}>{season}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup.Text>Country</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog?.useCase?.context, country: event.target.value}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.countries ? vars.context.countries.map((country) => <option key={country} value={country}>{country}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Road Type</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog?.useCase?.context, road: {...newCatalog?.useCase?.context?.road, type: event.target.value}}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.road.type ? vars.context.road.type.map((type) => <option key={type} value={type}>{type}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Road Surface Material</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog?.useCase?.context, road: {...newCatalog?.useCase?.context?.road, surfaceMaterial: event.target.value}}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.road.surfaceMaterial ? vars.context.road.surfaceMaterial.map((mat) => <option key={mat} value={mat}>{mat}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Road Surface Condition</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog?.useCase?.context, road: {...newCatalog?.useCase?.context?.road, surfaceCondition: event.target.value}}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.road.surfaceCondition ? vars.context.road.surfaceCondition.map((cond) => <option key={cond} value={cond}>{cond}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Light</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog?.useCase.context, light: event.target.value}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.light ? vars.context.light.map((light) => <option key={light} value={light}>{light}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Weather</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog?.useCase.context, weather: event.target.value}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.weather ? vars.context.weather.map((weather) => <option key={weather} value={weather}>{weather}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Speed Range</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog.useCase?.context, speedRange: event.target.value}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.speedRange ? vars.context.speedRange.map((speedRange) => <option key={speedRange} value={speedRange}>{speedRange}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Sky Cover</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog.useCase?.context, skyCover: event.target.value}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.skyCover ? vars.context.skyCover.map((skyCover) => <option key={skyCover} value={skyCover}>{skyCover}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Soil Type</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog.useCase?.context, soilType: event.target.value}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.soilType ? vars.context.soilType.map((soilType) => <option key={soilType} value={soilType}>{soilType}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Soil Coverage</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog.useCase?.context, soilCoverage: event.target.value}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.soilCoverage ? vars.context.soilCoverage.map((soilCoverage) => <option key={soilCoverage} value={soilCoverage}>{soilCoverage}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Glare Type</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog.useCase?.context, glareType: event.target.value}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.glareType ? vars.context.glareType.map((glareType) => <option key={glareType} value={glareType}>{glareType}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>Recording Type</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, context: {...newCatalog?.useCase?.context, recordingType: event.target.value}}})}>
                                            <option>Select one ...</option>
                                            {
                                                vars.context.recordingType ? vars.context.recordingType.map((recordingType) => <option key={recordingType} value={recordingType}>{recordingType}</option>) : <option></option>
                                            }
                                        </Form.Select>
                                    </InputGroup>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Scenario</Accordion.Header>
                            <Accordion.Body>
                            <InputGroup className='mb-3'>
                                <InputGroup.Text>Type</InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(event) => setNewCatalog({...newCatalog, useCase: {...newCatalog?.useCase, scenario: {type: event.target.value}}})}>
                                    <option>Select one ...</option>
                                    {
                                        vars.scenario.type ? vars.scenario.type.map((scenario) => <option key={scenario} value={scenario}>{scenario}</option>) : <option></option>
                                    }
                                </Form.Select>
                            </InputGroup>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewCatalog}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={createCatalog} style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} >
                        Create catalog
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className='right-panel'>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h2>{selectedCatalog?.name ? selectedCatalog?.name : ""}</h2>
                    <div >
                        <Button onClick={handleShowUseCase} style={{ backgroundColor: '#9B820C', borderColor: '#9B820C', marginRight: '1em'}}>Use Case</Button>
                        <Button onClick={deleteCatalog} style={{ backgroundColor: '#9B820C', borderColor: '#9B820C'}}><DeleteIcon></DeleteIcon></Button>
                    </div>
                </div>
                
                <div className='data-div'>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <p><b>Catalog Name   </b>{selectedCatalog?.name}</p>
                            <p><b>Catalog Status   </b>{selectedCatalog?.status}</p>
                        </div>
                        <div>
                            <p><b>Catalog Description   </b>{selectedCatalog?.description}</p>
                            <p><b>Catalog Goal   </b>{selectedCatalog?.currentCounter + "/" + selectedCatalog?.goal}</p>
                        </div>
                        <div>
                            <Button onClick={handleShowEditCatalog} style={{padding: 0, backgroundColor: '#9B820C', borderColor: '#9B820C'}}><EditIcon></EditIcon></Button>
                        </div>
                    </div>
                </div>

                <Modal show={showEditCatalog} onHide={handleCloseEditCatalog}>
                    <Modal.Header closeButton>
                    <Modal.Title>Update catalog</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <InputGroup className='mb-3'>
                                <InputGroup.Text>Catalog Name</InputGroup.Text>
                                <Form.Control 
                                    disabled
                                    placeholder={selectedCatalog?.name}
                                    defaultValue={selectedCatalog?.name}
                                    aria-describedby='basic-addon1'
                                    onChange={(event) => {
                                        //setCatalogUpdate({...catalogUpdate, name: event.target.value});
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                    <InputGroup.Text>Catalog Status</InputGroup.Text>
                                    <Form.Select aria-label="Default select example" defaultValue={selectedCatalog?.status} onChange={(event) => setCatalogUpdate({...catalogUpdate, status: event.target.value})}>
                                        <option>Select one ...</option>
                                        {
                                            vars.catalog.status ? vars.catalog.status.map((status) => <option key={status} value={status}>{status}</option>) : <option></option>
                                        }
                                    </Form.Select>
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <InputGroup.Text>Goal</InputGroup.Text>
                                <Form.Control 
                                    placeholder={selectedCatalog?.goal}
                                    defaultValue={selectedCatalog?.goal}
                                    aria-describedby='basic-addon1'
                                    onChange={(event) => {
                                        setCatalogUpdate({...catalogUpdate, goal: event.target.value});
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <InputGroup.Text>Description</InputGroup.Text>
                                <Form.Control 
                                    placeholder={selectedCatalog?.description}
                                    defaultValue={selectedCatalog?.description}
                                    aria-describedby='basic-addon1'
                                    onChange={(event) => {
                                        setCatalogUpdate({...catalogUpdate, description: event.target.value});
                                    }}
                                />
                            </InputGroup>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditCatalog}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={updateCatalog} style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} >
                        Update catalog
                    </Button>
                    </Modal.Footer>
                </Modal>
                
                <MaterialReactTable 
                    columns={videoListColumns} 
                    data={(selectedCatalog?.videos ? selectedCatalog?.videos : [])} 
                    state={{density: 'compact'}} 
                    initialState={{pagination: {pageSize: 10, pageIndex: 0}, showColumnFilters: true}} 
                    enableDensityToggle={false} 
                    muiTableBodyCellProps={{sx: {wordBreak: 'break-word', whiteSpace: 'normal'}}} 
                    muiTableProps={{sx: {tableLayout: 'fixed'}}} 
                    defaultColumn={{minSize: 10}} 
                        />
            </div>

            <Modal show={showUseCase} onHide={handleCloseUseCase}>
                <Modal.Header closeButton>
                    <Modal.Title>JSON File - Use Case</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <JsonView value={selectedCatalog?.useCase} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUseCase}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                className="p-3"
                position="top-end"
                style={{ zIndex: 2 }}
                >
                <Toast onClose={closeAlert} show={alertsConfig.show} bg={alertsConfig.variant}>
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">RESIM 3.0</strong>
                    </Toast.Header>
                    <Toast.Body style={{'color': 'white'}}>{alertsConfig.message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )

}

export default Catalogs;


