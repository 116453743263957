import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api_request from '../functions/api_request';
import { Spinner, Toast, ToastContainer, Modal, InputGroup, Form , Button } from 'react-bootstrap';
import { MaterialReactTable } from 'material-react-table';
import dateFormatter from '../functions/date_formatter';
import { Box, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import  DeleteIcon from '@mui/icons-material/Delete';

import vars from "../vars.json";

function Home(props) {
    const navigate = useNavigate();
    const [alertsConfig, setAlertsConfig] = useState({
        show: false,
        variant: "success",
        message: "Testing alert ..."
    });
    const [dataLoading, setDataLoading] = useState(true);
    const [videoList, setVideoList] = useState([]);

    const [videoToDelete, setVideoToDelete] = useState("");
    const [showDeleteVideo, setShowDeleteVideo] = useState(false);
    const handleShowDeleteVideo = () => setShowDeleteVideo(true);
    const handleCloseDeleteVideo = () => setShowDeleteVideo(false);

    const videoListColumns = [
        {
            accessorKey: 'path',
            header: 'Video Name',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },{
            accessorKey: 'status',
            header: 'Status',
            filterVariant: 'multi-select',
            Cell: ({ cell }) => (
                <Box
                  component="span"
                  sx={(theme) => ({
                    backgroundColor:
                      cell.getValue() === "HALTED"
                        ? theme.palette.error.light
                        : cell.getValue() === "PENDING"
                        ? theme.palette.warning.light
                        : theme.palette.success.light,
                    borderRadius: '0.25rem',
                    color: '#fff',
                    maxWidth: '9ch',
                    p: '0.25rem',
                  })}
                >
                    {cell.getValue()}
                </Box>
              ),
            filterSelectOptions: vars.video.status,
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },
        {
            header: 'Recording Date',
            accessorKey: 'recordedAt',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        },{
            accessorKey: 'updatedAt',
            header: 'Update Date',
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center'
            }
        }
    ];
    
    function closeAlert(){
        setAlertsConfig({...alertsConfig, show: false});
    }

    async function getVideos(){
        var dataReceived = await api_request("/videos", 'GET', localStorage.getItem('token'), null);
        if (dataReceived === false){
            props.setToken(null);
        } else {
            let newDataVideos = dataReceived.message;
            for (let i = 0; i < newDataVideos.length; i++){
                newDataVideos[i].updatedAt = newDataVideos[i].updatedAt ? dateFormatter(newDataVideos[i].updatedAt) : "-";
                newDataVideos[i].recordedAt = newDataVideos[i].recordedAt ? dateFormatter(newDataVideos[i].recordedAt) : "-";
                localStorage.setItem('videoPath', newDataVideos[i].path);
                localStorage.setItem('videoId', newDataVideos[i]._id);
            }
            setVideoList(newDataVideos);
            setDataLoading(false);
        }

    }

    async function deleteVideo() {
        if (videoToDelete === localStorage.getItem('videoPath')){
            handleCloseDeleteVideo();
            var dataReceived = await api_request("/video", 'DELETE', localStorage.getItem('token'), JSON.stringify({video: localStorage.getItem('videoId')}));
            if (dataReceived === false) {
                props.setToken(null);
            } else {
                if (dataReceived.error){
                    setAlertsConfig({
                        show: true,
                        variant: "danger",
                        message:"Error deleting video: " + dataReceived.message
                    });
                }
                getVideos();
                setVideoToDelete(null);
                handleCloseDeleteVideo();
            }
        } else {
            handleCloseDeleteVideo();
            alert("Error deleting video, name/path must be the same!");
            
        }
    }
    // eslint-disable-next-line 
    useEffect(() => {async function fethVideos(){await getVideos()} fethVideos();}, []);


    return(
        <div stle={{padding: '1em'}}>
           <div className='div-for-table'>
                {
                    dataLoading ? <div style={{display: 'flex', justifyContent: 'center', margin: '17vh'}}>
                        <Spinner animation='grow' style={{ color: '#9B820C'}}/>
                    </div> : <div style={{margin: '3em'}}>
                        <MaterialReactTable 
                            columns={videoListColumns} 
                            data={videoList} 
                            state={{density: 'comfortable'}} 
                            initialState={{pagination: {pageSize: 10, pageIndex: 0}, showColumnFilters: true}} 
                            enableDensityToggle={false} 
                            muiTableBodyCellProps={{sx: {wordBreak: 'break-word', whiteSpace: 'normal'}}} 
                            muiTableProps={{sx: {tableLayout: 'fixed'}}} 
                            defaultColumn={{minSize: 10}} 
                            enableRowActions
                            renderRowActions={({row, table}) => (
                                <Box>
                                    <Tooltip title="Show video data">
                                        <IconButton 
                                            color="#9B820C"
                                            onClick = {() => {
                                                localStorage.setItem('videoId', row.original._id);
                                                localStorage.setItem('videoPath', row.original.path);
                                                navigate('/video');
                                            }}
                                        >
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                    { (localStorage.getItem('userRole') === "ADMIN") ?
                                        <Tooltip title="Delete video">
                                            <IconButton 
                                                color="#9B820C"
                                                onClick = {() => {
                                                    localStorage.setItem('videoId', row.original._id);
                                                    localStorage.setItem('videoPath', row.original.path);
                                                    handleShowDeleteVideo();
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip> : <div></div>
                                    }
                                </Box>
                            )}
                            />
                    </div>
                }
            </div>

            <Modal show={showDeleteVideo} onHide={handleCloseDeleteVideo}>
                <Modal.Header closeButton>
                <Modal.Title>Delete video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please, introduce the video path to verify it ...</p>
                    <Form>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text>Video Path</InputGroup.Text>
                            <Form.Control 
                                placeholder={ "Introduce " + localStorage.getItem('videoPath') + " ..."}
                                aria-describedby='basic-addon1'
                                onChange={(event) => {
                                    setVideoToDelete(event.target.value);
                                }}
                            />
                        </InputGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDeleteVideo}>
                    Close
                </Button>
                <Button variant="danger" onClick={deleteVideo} >
                    Delete video
                </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                className="p-3"
                position="top-end"
                style={{ zIndex: 2 }}
                >
                <Toast onClose={closeAlert} show={alertsConfig.show} bg={alertsConfig.variant}>
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">RESIM 3.0</strong>
                    </Toast.Header>
                    <Toast.Body style={{'color': 'white'}}>{alertsConfig.message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )

}

export default Home;


