import { useState } from "react";
import { Form, Row, Col, InputGroup, Button,  } from "react-bootstrap";
const config = require("../config.json");
const vars = require("../vars.json");

function RegisterUser(props){
    const url = config['url'];
    const roles = vars.user.role;

    const [newUser, setNewUser] = useState({
        email: '',
        password: '',
        rep_password: '',
        role: ''
    });

    async function doRegister(){
        if(newUser.password === newUser.rep_password && newUser.role !== ""){
            let options = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                    'Access-Control-Allow-Origin': '*',
                    'x-access-token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    "email": newUser.email,
                    "password": newUser.password,
                    "role": newUser.role
                })
            }
            let response = await fetch(url + '/register', options).catch(err => {
                console.log(err);
            });
            if (response.status !== 200) {
                if(response.status === 400) {
                    var error_response = await response.json();
                    console.log(error_response.message);
                    alert('Error: ' + error_response.message);
                } else {
                    console.log("Can't sign up the user");
                    alert("Error: Can't sign up the user");
                }
            } else {
                var dataReceived = await response.json();
                alert("New user with role " + dataReceived.message.role + ", registered correctly!")
            }
        } else {
            alert("Passwords don't match!");
        }
    }

    return(
        <div stle={{padding: '1em'}}>
            <div className='div-for-table'>
                <div style={{padding: '3em'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                        <h3>Register User</h3>
                    </div>
                    <p>Tool for registering new users to RESIM 3.0 platform.</p>
                    <hr></hr>

                    <Row>
                        <Col>
                            <Row>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Email Address</InputGroup.Text>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email address of the new user"
                                        onChange={(e) => setNewUser({ email: e.target.value, password: newUser.password, rep_password: newUser.rep_password, role: newUser.role})}
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Password</InputGroup.Text>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password of the new user"
                                        onChange={(e) => setNewUser({ email: newUser.email, password: e.target.value, rep_password: newUser.rep_password, role: newUser.role})}
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Repeat Password</InputGroup.Text>
                                    <Form.Control
                                        type="password"
                                        placeholder="Repeat the password of the new user"
                                        onChange={(e) => setNewUser({ email: newUser.email, password: newUser.password, rep_password: e.target.value, role: newUser.role})}
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Team</InputGroup.Text>
                                    <Form.Select aria-label="Default select example" onChange={(e) => setNewUser({email: newUser.email, password: newUser.password, rep_password: newUser.rep_password, role: e.target.value})}>
                                        <option>Please, select the team of the user ...</option>
                                        {
                                            roles.map((role) => 
                                                <option>{role}</option>
                                            )
                                        }
                                    </Form.Select>
                                </InputGroup>
                                <Button onClick={doRegister} style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}}>Register</Button>
                            </Row>
                            
                        </Col>
                        <Col>
                           
                        </Col>
                    </Row>
                    
                </div>
            </div>
        </div>
    )
}

export default RegisterUser;