import { useState } from "react" 
import { Image, Modal, Form, InputGroup, Button } from "react-bootstrap";
import logo from "../img/logo_ficosa.png"
const config = require("../config.json");

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
    const url = config['url'];
    const [user, setUser] = useState({
        email: '',
        password: ''
    })
    const [updatePassword, setUpdatePassword] = useState({
        email: '',
        password: '',
        newPassword: ''
    });
    const [showUpdatePwd, setShowUpdatePwd] = useState(false);
    const handleShowUpdatePwd = () => setShowUpdatePwd(true);
    const handleCloseUpdatePwd = () => setShowUpdatePwd(false);

    async function logIn(e) {
        e.preventDefault();
        let options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify({
                "email": user.email,
                "password": user.password,
            })
        }
        let response = await fetch(url + '/login', options).catch(err => {
            console.log(err);
        });
        if (response.status !== 200) {
            if(response.status === 400) {
                var error_response = await response.json();
                console.log(error_response.message);
                alert('Error: ' + error_response.message);
            } else {
                console.log("Can't log in the user");
                alert("Error: Can't log in the user");
            }
            
        } else {
            var dataReceived = await response.json();
            localStorage.setItem('token', dataReceived.message.token);
            localStorage.setItem('email', user.email);
            localStorage.setItem('userId', dataReceived.message.id);
            localStorage.setItem('userRole', dataReceived.message.role);
            props.setToken(dataReceived.message.token);
        }
    }

    async function updatePwd(){
        let options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify({
                "email": updatePassword.email,
                "password": updatePassword.password,
                "newPassword": updatePassword.newPassword
            })
        }
        let response = await fetch(url + '/updatepwd', options).catch(err => {
            console.log(err);
        });
        if (response.status !== 200) {
            if(response.status === 400) {
                var error_response = await response.json();
                console.log(error_response.message);
                alert('Error: ' + error_response.message);
            } else {
                alert("Error: Can't update password");
            }
        } else {
            alert("Password updated correctly!");
        }
        handleCloseUpdatePwd();
    }

    return (
        <div style={{backgroundColor: '#dbd4af'}}>
            <div className="Auth-form-container">
                <form className="Auth-form" onSubmit={logIn}>
                    <div className="Auth-form-content">
                        <div style={{textAlign: 'center', marginBottom: '10px'}}>
                            <Image src={logo} width='150px'/>
                        </div>
                        <h3 className="Auth-form-title">Sign In</h3>

                        <div className="text-center">
                            {/*
                            Not registered yet?{" "}
                            <span onClick={changeAuthMode} style={{color: '#9B820C'}}>
                                Sign Up
                            </span>
                            */}
                        </div>
                        <div className="form-group mt-3">
                            <label>Email address</label>
                            <input
                                type="email"
                                className="form-control mt-1"
                                placeholder="Enter email"
                                onChange={(e) => setUser({ email: e.target.value, password: user.password })}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Password</label>
                            <input
                                type="password"
                                className="form-control mt-1"
                                placeholder="Enter password"
                                onChange={(e) => setUser({ email: user.email, password: e.target.value })}
                            />
                        </div>
                        <div className="text-center">
                            <span onClick={handleShowUpdatePwd} style={{color: '#9B820C'}}>
                                Update Password
                            </span>
                        </div>
                        <div className="d-grid gap-2 mt-4 mb-2">
                            <button type="submit" className="btn btn-primary" style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}}>
                                Sign In
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <Modal show={showUpdatePwd} onHide={handleCloseUpdatePwd}>
            <Modal.Header closeButton>
            <Modal.Title>Update Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text>Email</InputGroup.Text>
                        <Form.Control 
                            placeholder="Introduce email ..."
                            aria-describedby='basic-addon1'
                            onChange={(event) => {
                                setUpdatePassword({...updatePassword, email: event.target.value})
                            }}
                        />
                    </InputGroup>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text>Current Password</InputGroup.Text>
                        <Form.Control 
                            placeholder="Introduce your current password ..."
                            aria-describedby='basic-addon1'
                            type="password"
                            onChange={(event) => {
                                setUpdatePassword({...updatePassword, password: event.target.value})
                            }}
                        />
                    </InputGroup>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text>New Password</InputGroup.Text>
                        <Form.Control 
                            placeholder="Introduce the new password ..."
                            aria-describedby='basic-addon1'
                            type="password"
                            onChange={(event) => {
                                setUpdatePassword({...updatePassword, newPassword: event.target.value})
                            }}
                        />
                    </InputGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseUpdatePwd}>
                Close
            </Button>
            <Button variant="primary" style={{backgroundColor: '#9B820C', borderColor: '#9B820C'}} onClick={updatePwd} >
                Update
            </Button>
            </Modal.Footer>
        </Modal>
        </div>
    )
    
}

