import { Nav, NavDropdown, NavLink, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoExit, IoAlertCircleOutline  } from "react-icons/io5";
import { GrCatalog } from "react-icons/gr";
import { GoProject } from "react-icons/go";
import { FaUser } from "react-icons/fa";
import HomeIcon from '@mui/icons-material/Home';
import VideocamIcon from '@mui/icons-material/Videocam';
import PlaceIcon from '@mui/icons-material/Place';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';


function NavigationBar(props) {

    function logOut() {
        props.setToken(null);
        localStorage.removeItem('token');
    }

    return (
        <div style={{'position': 'fixed', 'width': '-webkit-fill-available', 'zIndex': 2}}>

            <Navbar collapseOnSelect expand="lg" variant="dark" style={{'backgroundColor': '#9B820C'}}>

                <Navbar.Brand href="/" style={{marginLeft: '20px'}}>
                    <h3>RESIM 3.0</h3>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navScroll" data-bs-target="#navbarScroll"/>
                <Navbar.Collapse id="navbarScroll" style={{'marginLeft': '20px', 'marginRight': '30px'}}>
                    <Nav className="me-auto">
                        <NavLink eventKey="1" as={Link} to="/"><HomeIcon /> Home</NavLink>
                        <NavLink eventKey="2" as={Link} to="/video"><VideocamIcon /> Video</NavLink>
                        <NavLink eventKey="5" as={Link} to="/scenarios"><PlaceIcon /> Scenarios</NavLink>
                        <NavLink eventKey="6" as={Link} to="/ego"><DirectionsCarIcon /> EGO Vehicles</NavLink>
                        <NavDropdown title="Management" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/catalogs"><GrCatalog /> Catalogs</NavDropdown.Item>
                            <NavDropdown.Item href="/projects"><GoProject /> Projects</NavDropdown.Item>
                        </NavDropdown>

                    </Nav>
                    <Nav>    
                        <NavDropdown title="Configuration" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/alerts"><IoAlertCircleOutline /> Alerts</NavDropdown.Item>

                            {
                                (localStorage.getItem("userRole") === "ADMIN") ?  <NavDropdown.Item href="/register"><FaUser /> Register User</NavDropdown.Item>: null
                            } 
                        </NavDropdown>
                        
                        <NavLink eventKey="6" onClick={logOut}><IoExit /> Log Out</NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

        </div>
    )
}

export default NavigationBar;